interface tmp {
    data?: any;
    originalTitle: string;
    setTitle: (title: string) => void;
    headers: (obj: { [x: string]: any }) => { [x: string]: any };
    mergeObjectRecursive: (...any) => unknown;
    isObject: (item: any) => boolean;
}

const tmp: tmp = {
    // @ts-ignore
    data: JSON.parse(document.getElementById("tmp-data").innerHTML),
    originalTitle: document.title,

    setTitle: (title: string) => {
        document.title = title + " - TMP";
    },

    headers: (obj: any = {}) => {
        return {
            "X-CSRF-TOKEN": tmp.data.csrf_token,
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            ...obj,
        };
    },

    mergeObjectRecursive: (...args): { [x: string]: any } => {
        // Variables
        var extended = {};
        var deep = true;
        var i = 0;
        var length = args.length;

        // Merge the object into the extended object
        var merge = function (obj) {
            for (var prop in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                    // If deep merge and property is an object, merge properties
                    if (
                        deep &&
                        Object.prototype.toString.call(obj[prop]) ===
                            "[object Object]"
                    ) {
                        extended[prop] = tmp.mergeObjectRecursive(
                            extended[prop],
                            obj[prop]
                        );
                    } else {
                        extended[prop] = obj[prop];
                    }
                }
            }
        };

        // Loop through each object and conduct a merge
        for (; i < length; i++) {
            var obj = args[i];
            merge(obj);
        }

        return extended;
    },

    isObject: (item): boolean => {
        return item && typeof item === "object" && !Array.isArray(item);
    },

    /*mergeObjectRecursive: (target, ...sources) => {
        if (!sources.length) {
            return target;
        }

        const source = sources.shift();

        if (tmp.isObject(target) && tmp.isObject(source)) {
            for (const key in source) {
                if (tmp.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    tmp.mergeObjectRecursive(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return tmp.mergeObjectRecursive(target, ...sources);
    },*/
};

(window as any).tmp = tmp;

declare global {
    interface Date {
        addDays(days: number): Date;
    }
}

Date.prototype.addDays = function (days: number) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export default tmp;
